  /* .full_bg {
      background: url(../../assets/banner_bg.jpg) no-repeat center center;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
      min-height: 82vh;
  }

  .heading {
      color: #f36a2b;
      font-size: 20px;
  }

  .text {
      font-size: medium;
      color: black;
  } */

  .login-btn {
      text-decoration: none;
  }

  .sign-up-link {
    color: orange;
    text-decoration-line: underline;
  }

  .msg {
      color: red;
      font-size: 12px;
      display: flex;
      justify-content: flex-start;
      padding: 10px
  }

  .pwd-container {
    display: flex;
    align-items: center;
    height: 80vh;
    justify-content: center;
    flex-direction: column;
}
.text{
    text-decoration-line: underline;
    color: var(--primary);
}