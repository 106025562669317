.modal-container {
  background-color: unset !important;
  width: 85vw;
  height: 95vh;
  border-radius: 5px;
  background-color: "#FFFF00";
  position: absolute;
  left: 50%;
  top: 55%;
  transform: translate(-50%, -50%);
  align-items: center;
}

.box-shadow {
  background-color: #ffffff;
  box-shadow: 0 0 0 40vmax rgba(0, 0, 0, .6);
  /* max-width: 100%;
  max-height: 100%; */
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}