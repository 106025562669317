/* @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400&display=swap');

*{
  font-family: poppins,sans-serif;
}
.login_btn{
  width:80px;
  height: auto;
  background-color: #f36a2b;
  text-align: center;
  padding:8px 6px;
  border-radius: 5px;
  border-style: hidden;
  font-size: 12px;
  letter-spacing: 2px;
  color: white;
  margin-left: 70%;
  margin-top: 10%;
}
.publish{
  width:80px;
  height: auto;
  background-color: #f36a2b;
  text-align: center;
  padding:8px 6px;
  border-radius: 5px;
  border-style: hidden;
  font-size: 12px;
  letter-spacing: 2px;
  color: white;
  margin-left: 1%;
  margin-top:5%;
} */

.card{
  height: 100%;
}