body {
  font-size: 15px;
  color: #000000;
  font-family: 'Poppins', sans-serif;
}

a, a:hover {
  color: #ffffff !important;
}

:root {
  --primary: #f36a2b;
  --bg: hsl(330, 6%, 7%);
  --form-bg: #f5f5f5;
  --primary_bg: #f36a2b;
  --white_color: #ffffff !important;
}

.img-fluid {
  max-width: 100%;
  height: auto;
  width: 100%;
}

input, select {
  background-color: #f5f5f5 !important;
}

.primary_color {
  background-color: var(--primary) !important;
}

.primary_font_color, a.primary_font_color {
  color: var(--primary) !important;
  text-decoration: none
}

.white_color {
  color: var(--white_color)
}

.forgot_password {
  color: var(--primary)
}

.font-12 {
  font-size: 12px
}

.font-13 {
  font-size: 13px
}

.form_bg {
  background-color: var(--form-bg);
}

.main_btn {
  background-color: var(--primary) !important;
  color: #fff;
}

.form-check-input:checked {
  background-color: #0d6efd !IMPORTANT;
  border-color: #0d6efd !IMPORTANT;
}

.form-check {
  text-align: left;
}

.full_bg {
  background: url(../images/banner_bg.jpg) no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  min-height: 90vh;
}

.lh-base-new {
  line-height: 1.8 !important;
}

.login_heading {
  color: var(--primary);
  font-size: 34px;
}

.header-link {
  font-size: 14px;
}

.form-control, button.btn, .form-select, .btn {
  font-size: 14px;
  border-radius: 25px;
}

.admin-ui .form-control, button.btn, .form-select, .btn {
  border-radius: 5px;
  display: inline;
}

.body_height {
  min-height: 90vh;
}

footer {
  background-color: var(--bg);
  min-height: 10vh;
}

  footer.p-3 a {
      font-size: 11px;
      text-decoration: none;
  }

/*after login*/
.header_bg {
  background-color: var(--bg);
}

.welcome_heading {
  font-size: 24px;
  color: var(--primary)
}

.text-center.forgot_password.fs-5.p-2 {
  border-radius: 10px;
  border: solid 1px #ccc;
}

.mb-5.choose-number {
  border: solid 1px #ccc;
  padding: 20px;
  border-radius: 15px;
}

.text-center.forgot_password.fs-5.p-2 {
  max-width: 80%;
  text-align: center;
  margin: -45px auto auto;
}

.text-center.forgot_password.fs-5.p-2 {
  border-radius: 10px;
  border: solid 1px #ccc;
  margin-top: -45px;
  display: block;
  background-color: #fff;
}

.selected {
  border: solid 1px var(--primary)
}

.circle {
  border: solid 3px var(--primary);
  display: inline-block;
  border-radius: 50%;
  width: 40px;
  padding: 5px 13px;
}


.time_style a {
  background-color: #f5f5f5;
  margin-bottom: 25px;
  color: #333 !important;
  border-radius: 25px;
  padding: 5px 15px;
  font-weight: 500;
  float: left;
  margin-right: 12px;
  text-decoration: none;
}

.time_style p {
  color: #333;
  text-align: start;
  font-size: 14px;
  font-weight: 500;
}

a.selectedbg {
  background-color: var(--primary_bg);
  color: var(--white_color) !important;
  font-weight: bold;
}


.inspection_cost p.time::after, p.cost::after {
  content: '';
  border-bottom: solid 1px #ccc;
  width: 100%;
  display: inline-block;
}

.cost span, p.total.mb-1 span {
  width: 75%;
  display: inline-block;
}

p.total.mb-1 span {
  width: 80%;
  display: inline-block;
}

.gst {
  text-align: end;
  width: 95%;
  font-size: 12px;
}

a.fs-6.mb-3 {
  display: inline-block;
  background-color: #000;
  width: auto;
  border-radius: 3px;
  margin-left: 15px;
}

.inspection_cost p {
  color: #000000
}

.inspection_cost {
  border: solid 1px #cccccc;
  border-radius: 10px;
  background-color: #f6f9fc;
  padding: 10px;
}



@media screen and (min-width:768px) {
  .time_style.col-md-4.mb-3 {
      margin-top: 20px;
  }
}

@media screen and (max-width:767px) {
  .mobile-select {
      background: 0 0 !important;
      -webkit-appearance: none;
      text-align: center;
      border: 0;
      float: left;
      padding: 12px 5px;
      color: #000;
      background-color: #fff;
      background-image: none;
      text-transform: uppercase;
  }

  .sort-outer {
      position: relative;
      width: 50%;
      overflow: hidden;
      float: left;
      text-align: center;
      border: solid 2px #ccc;
  }

      .sort-outer em {
          position: absolute;
          left: 30px;
          top: 10px;
      }

  button.btn.filter-btn {
      font-size: 14px;
      font-weight: 600;
  }

  .filter-outer {
      width: 48%;
      float: left;
      border: solid;
      padding: 5px 5px;
      color: #000;
      background-color: #fff;
      background-image: none;
      border: solid 2px #ccc;
      margin-left: 5px;
  }

  a.clr-btn, a.apply-btn {
      width: 40%;
      display: inline-block;
      padding: 7px;
      border: solid 2px #ccc;
      font-size: 14px;
      font-weight: 600;
      font-weight: 600;
      color: #000 !IMPORTANT;
      text-decoration: none;
      text-transform: uppercase;
  }

  .accordion.mx-auto.mt-3 {
      width: 81%;
  }
}



/* .admin-ui.full_bg {
  background: url(..//images/admin_banner_bg.jpg) no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  min-height: 100vh;
} */

.admin-ui .login-bg {
  background-color: #000;
  opacity: 0.8;
  padding: 25px;
  border-radius: 25px;
}


label.comments {
  color: #666;
  font-size: 14px;
  padding: 10px;
}

.prd-listing-page select.form-select {
  font-weight: 600;
  border-radius: 0;
}

.page-item.active .page-link {
  background-color: var(--primary) !important;
}

span.page-link {
  color: #333;
  cursor: pointer
}

.shape {
  background: #000;
  color: #fff;
  padding: 8px 20px 8px 8px;
  font-size: 14px;
  width: auto;
  display: inline-block;
}


  .shape.arrow-chevron {
      clip-path: polygon(100% 0%, 90% 50%, 100% 100%, 0% 100%, 0% 0%, 0% 0%);
  }



.filter-selection p.filter-outer-result {
  display: inline-block;
  position: relative;
}

.filter-selection em a {
  position: absolute;
  right: -10px;
  top: -6px;
}

.filter-selection em a {
  color: #000000 !important;
  cursor: pointer;
}

.filter-selection span {
  background-color: var(--primary) !important;
  display: inline-block;
  padding: 5px 15px;
  color: #fff;
  font-size: 14px;
}


svg.bi.bi-emoji-smile {
  font-size: 45px;
  width: 50px;
  height: 50px;
  margin-bottom: 21px;
  color: green;
}




.card.payment span, .card.payment label {
  color: #000
}
.card.payment {
  text-align:start
}
button.btn.primay.primary_color.text-white.w-100.p-3, button.btn.primay.primary_color.text-white {
  border-radius: 50px;
}
  .thank-you-pop h1{ color:#000}
.thank-you-pop p {color:#333}