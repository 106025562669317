body {
    font-size: 15px;
    color:#000000;
    font-family: 'Poppins', sans-serif;
}

a,a:hover {color: #ffffff !important;}

:root {
    --primary: #f36a2b;
    --bg:hsl(330, 6%, 7%);
    --form-bg :#f5f5f5;
    --primary_bg:#f36a2b;
    --white_color:#ffffff;
}

.img-fluid {
    max-width: 100%;
    height: auto;
    width:100%;
}

input, select {
    background-color: #f5f5f5!important;
    
}
.primary_color { background-color: var(--primary) !important;}
.primary_font_color, a.primary_font_color { 
    color: var(--primary) !important; text-decoration:none
}
.white_color {color:var(--white_color)}
.forgot_password { color: var(--primary)}
.font-12 {font-size:12px}
.form_bg {background-color: var(--form-bg);}
.main_btn {
    background-color: var(--primary) !important; color:#fff;
}
/* .full_bg {
    background: url(../images/banner_bg.jpg) no-repeat center center; 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    min-height: 90vh;
} */
.lh-base-new {
    line-height: 1.8 !important;
}
.login_heading {
    color:var(--primary);
    font-size: 34px;
}
.header-link  {font-size: 14px;}
.form-control, button.btn, .form-select, .btn {
    font-size: 14px;
    border-radius: 25px;
}

.admin-ui .form-control, button.btn, .form-select, .btn {
    border-radius: 5px;
    display: inline;
}
    .body_height {
        min-height: 90vh;
    }

footer { background-color:var(--bg); min-height: 10vh; }
footer.p-3 a {font-size: 11px; text-decoration: none;}

/*after login*/
.header_bg {background-color:var(--bg);}
.welcome_heading {font-size: 24px; color:var(--primary)}

.text-center.forgot_password.fs-5.p-2 {
    border-radius: 10px;
    border: solid 1px #ccc;
  }

  .mb-5.choose-number {
    border: solid 1px #ccc;
    padding: 20px;
    border-radius: 15px;
  }
  .text-center.forgot_password.fs-5.p-2 {
    max-width: 80%;
    text-align: center;
    margin: -45px auto auto;
  }

  .text-center.forgot_password.fs-5.p-2 {
    border-radius: 10px;
    border: solid 1px #ccc;
    margin-top: -45px;
    display: block;
    background-color: #fff;
  }
  .selected{border:solid 1px  var(--primary)}

  .circle {
  border: solid 3px var(--primary);
  display: inline-block;
  border-radius: 50%;
  width:40px;
  padding: 5px 13px;}

  .time_style a {background-color: #f5f5f5;
    margin-bottom: 25px;
    color: #333;
    border-radius: 25px;
    padding: 5px 15px;
    font-weight: 500;
    float:left;
    margin-right: 12px;
    text-decoration: none;}

    .time_style p {    color: #333;
      text-align: start;
      font-size: 14px;
      font-weight: 500;}

    a.selectedbg {background-color: var(--primary_bg); color:var(--white_color); font-weight: bold;}

    
   .inspection_cost p.time::after, p.cost::after{
      content: '';
      border-bottom: solid 1px #ccc;
      width: 100%;
      display: inline-block;
   }

   .cost span, p.total.mb-1 span {
    width: 75%;
    display: inline-block;
}

p.total.mb-1 span {
  width: 80%;
  display: inline-block;
}
.gst {text-align: end;
  width: 95%;
  font-size: 12px;}

  span.fs-6.mb-3 {
    display: inline-block;
    background-color: #000;
    width: auto;
    border-radius: 3px;
    margin-left: 15px;
}
.inspection_cost p {color:#000000}
.inspection_cost {border:solid 1px #cccccc; border-radius: 10px; background-color: #f6f9fc; padding:10px;}



    @media  screen and (min-width:768px) {
      .time_style.col-md-4.mb-3 {
        margin-top: 20px;
    }
      
    }

    @media screen and (max-width:767px){
        .d-block.d-sm-none.sort {
            width: 50%;
            float: left;
        }
    }
    

.admin-ui.full_bg {background: url(../images/admin_banner_bg.jpg) no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  min-height: 100vh;}

  .admin-ui .login-bg {
    background-color: #000;
    opacity: 0.8;
    padding: 25px;
    border-radius: 25px;
  }


label.comments {
    color: #666;
    font-size: 14px;
    padding: 10px;
}
.prd-listing-page select.form-select {
    font-weight: 600;
    border-radius: 0;
}

.page-item.active .page-link {
    background-color: var(--primary) !important;
}
span.page-link {
    color: #333;
    cursor:pointer
}



.shape {
    background: #000;
    color: #fff;
    padding: 8px 20px 8px 8px;
    font-size: 14px;
    width: auto;
    display: inline-block;
   
}

    .shape.arrow-chevron {
        
        clip-path: polygon(100% 0%, 90% 50%, 100% 100%, 0% 100%, 0% 0%, 0% 0%);
    }

.pagenation{
    color: #000;
    background-color:var(--primary);
}

.box{
    width:100%;
}

.car-info-row{
    display: flex;
    flex-direction: column;
    width: auto;
}

.invalid{
    border-color: rgb(238, 62, 62);
}

.invalid::-webkit-input-placeholder {
    color: red;
}

.publish-text{
    color:rgb(33, 156, 9);
    font-size: 12px;
    display: flex;
    justify-content: flex-start;
    padding:10px
}

.publish-text-err{
    color:rgb(211, 0, 0);
    font-size: 12px;
    display: flex;
    justify-content: flex-start;
    padding:10px
}

.form-msg{
    color: red;
}
/* .carousel{
    height: 50%;
} */

.carousel{
    width: fit-content;
    height: fit-content;
}
.carousel-item {
    width: 50vw;
    height: 50vh;
}
.image {
    width: 60vw;
    height: 60vh;
}