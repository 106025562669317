.btnlink {
  background: none !important;
  border: none;
  padding: 0 !important;
  font-family: arial, sans-serif;
  color: #069;
  text-decoration: underline;
  cursor: pointer;
  margin-left: 20px;
}

.form-control:disabled {
  cursor: not-allowed;
}